import { message } from 'antd';
import type { woodService } from 'server/type-file';
import { hc } from 'hono/client';
import { isNil } from 'lodash-es';
import { FASTIFY_API } from '../../config/local';
type AppType = typeof woodService;

export const honoClient = hc<AppType>(`${FASTIFY_API}/wood`, {
	async fetch(url, options) {
		try {
			const idToken = sessionStorage.getItem('idToken');
			// console.log('sessionStorage', idToken)
			if (isNil(idToken)) {
				location.href = '/wood/signIn';
				return;
			}

			options.headers.set('Authorization', idToken && `Bearer ${idToken}`);
			return fetch(url, options).then(async (res) => {
				if (res.status !== 200) {
					const body = await res.json();
					// console.log(body)
					if (body.code === 'UNAUTHORIZED') {
						// console.log('hono idToken', idToken)
						location.href = '/wood/signIn';

						throw new Error('UNAUTHORIZED');
					}

					if (body?.error?.name === 'ZodError') {
						throw new Error(body?.error?.issues?.at(0).message);
					}

					throw new Error(body.message);
				}

				return res;
			});
		} catch (err) {
			console.log(err);
			message.error(err);
			// location.href = '/wood/signIn';

			// throw new Error('fetch error');
		}
	},
});
